import React from "react";
import { Layout } from "../../layouts/Layout";
import { BrandSix } from "../../components/Brand/BrandSix";
import { ServicesThree } from "../../components/Services/ServicesThree";
import { ContactAreaOne } from "../../components/ContactAreas/ContactAreaOne";
import { TestimonialAreaOne } from "../../components/TestimonialAreas/TestimonialAreaOne";

const ServicesPageFour = () => {
  return (
    <Layout breadcrumb={"Services"}  title={'Our Services'}  header={4} footer={1}>
      {/* services */}
      <ServicesThree isServicePage />

      {/* testimonial-area */}
      <TestimonialAreaOne />

      {/* contact-area */}
      <ContactAreaOne />
    </Layout>
  );
};

export default ServicesPageFour;
