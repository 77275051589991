import React, { useState } from "react";

export const EstimateForm = () => {
  const [range, setRange] = useState(150000);

  return (
    <div className="estimate-form-wrap">
      <form action="#">
        <div className="form-grp">
          <input type="text" placeholder="*Full Name" required/>
        </div>
        <div className="form-grp">
          <input type="email" placeholder="*Email Address" required/>
        </div>
        <div className="form-grp">
          <input type="number" placeholder="*Phone Number" required/>
        </div>
        <div className="range-slider-wrap">
          <div className="content-top">
            <p>Business Profits:</p>
            <span>
              $<strong id="rangeValue">{range}</strong>
            </span>
          </div>

          <input
            className="range"
            type="range"
            value={range}
            min="65000"
            max="999999"
            onChange={(e) => setRange(e.target.value)}
            onMouseMove={(e) => setRange(e.target.value)}
          />
        </div>

        <button className="btn btn-three" type="submit">
          CALCULATE ESTIMATE*
        </button><br/><br/>
        *Our team will get back to you with an estimate and quotation.
      </form>
    </div>
  );
};
