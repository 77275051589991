import React from "react";
import {
  H4_TESTIMONIAL_IMG,
  H4_TESTIMONIAL_IMG_SHAPE,
  H4_TESTIMONIAL_SHAPE01,
  H4_TESTIMONIAL_SHAPE02,
  QUOTE02,
} from "../../lib/assets";
import {
  SlickSlider,
  SlickSliderArrowNext,
  SlickSliderArrowPrev,
} from "../SlickSlider/SlickSlider";

export const TestimonialAreaFour = () => {
  const slick_settings_brands = {
    dots: false,
    infinite: true,
    speed: 5000,
    autoplay: true,
    arrows: true,
    prevArrow: (
      <SlickSliderArrowPrev classNameWrapper="testimonial-nav-four testimonial-nav-four-prev" />
    ),
    nextArrow: (
      <SlickSliderArrowNext classNameWrapper="testimonial-nav-four testimonial-nav-four-next" />
    ),
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="testimonial-area-four testimonial-bg-four">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="testimonial-img-four">
              <img src={H4_TESTIMONIAL_IMG} alt="" />
              <div className="icon">
                <img src={QUOTE02} alt="" />
              </div>
              <img src={H4_TESTIMONIAL_IMG_SHAPE} alt="" className="shape" />
            </div>
          </div>

          <div className="col-lg-7">
            <div className="testimonial-item-wrap-four">
              <div className="testimonial-active-four">
                <SlickSlider settings={slick_settings_brands}>
                  <div className="testimonial-item-four">
                    <div className="testimonial-content-four">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “Nomadic has been a lifesaver for my freelance business! Managing receivables used to be such a headache, and I was never sure if I was optimizing my income properly. With their solution, not only did it streamline the entire invoicing process, but it also showed me how to reduce my tax liability by structuring my payouts better. My tax bill was significantly lower this year!”
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Jessica W.</h2>
                        <span>Freelance Designer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-four">
                    <div className="testimonial-content-four">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “As a content creator, managing multiple income streams from sponsorships, ads, and freelance gigs was overwhelming, especially during tax season. Nomadic helped me optimize when/how I receive payments and showed me how to reduce my tax liability without affecting my cash flow. It’s like having a personal financial advisor for my business! Highly recommend it to fellow creators!””
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Laura M.</h2>
                        <span>Content Creator & Influencer</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-four">
                    <div className="testimonial-content-four">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “As a small business owner, balancing receivables and managing payouts can get tricky, especially when it comes to taxes. Nomadic's solution completely changed the way I handle my income. They helped me structure my payouts in a way that minimized my tax exposure while ensuring a steady cash flow. If you want a smarter way to handle taxes and payments, look no further!”
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Daniel K.</h2>
                        <span>Agency Owner</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-four">
                    <div className="testimonial-content-four">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “I run a small tech startup, and taxes were always a pain—especially when it came to handling client payments and making sure we weren’t overpaying. Nomadic made everything easy! They track receivables and optimize when and how to take payouts to reduce our tax burden. We’ve saved a significant amount, and the process is more efficient than ever!”
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Rebecca S.</h2>
                        <span>Startup Founder</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-four">
                    <div className="testimonial-content-four">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                      <p>
                        “Being an independent contractor means I have to manage everything myself, and taxes are always the most stressful part. Nomadic's tax optimization tool helped me align my payouts and receivables perfectly to minimize what I owe at the end of the year. It’s intuitive, and the financial insights it provides are invaluable. My tax liability was slashed by over 80%!”
                      </p>
                      <div className="testimonial-info">
                        <h2 className="title">Mark H.</h2>
                        <span>Independent Consultant</span>
                      </div>
                    </div>
                  </div>
                </SlickSlider>
              </div>

              {/* <div className="testimonial-nav-four"></div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="testimonial-shape-wrap-four">
        <img
          src={H4_TESTIMONIAL_SHAPE01}
          alt=""
          data-aos="fade-up-right"
          data-aos-delay="200"
        />
        <img
          src={H4_TESTIMONIAL_SHAPE02}
          alt=""
          data-aos="fade-down-left"
          data-aos-delay="200"
        />
      </div>
    </section>
  );
};
