import {
  H2_SERVICES_IMG01,
  H2_SERVICES_IMG02,
  H2_SERVICES_IMG03,
  H2_SERVICES_IMG04,
  H4_SERVICES_IMG01,
  H4_SERVICES_IMG02,
  H4_SERVICES_IMG03,
  H4_SERVICES_IMG04,
  SERVICES_IMG01,
  SERVICES_IMG02,
  SERVICES_IMG03,
  SERVICES_ITEM_SHAPE,
} from "../lib/assets";

export const SERVICES_TWO_LIST = [
  {
    id: 1,
    src: H2_SERVICES_IMG01,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-layers",
    href: "/services",
    title: "Digital Nomad Visas",
    subtitle: "Specialized Visa Service For Remote Workers & Nomads.",
  },
  {
    id: 2,
    src: H2_SERVICES_IMG02,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-house",
    href: "/services",
    title: "Realestate Discovery",
    subtitle: "Understand And Experience Global Property Markets.",
  },
  {
    id: 3,
    src: H2_SERVICES_IMG03,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-profit",
    href: "/services",
    title: "Market Expansion",
    subtitle: "On-The-Ground Support To Expand Into New Markets.",
  },
  {
    id: 4,
    src: H2_SERVICES_IMG04,
    srcShape: SERVICES_ITEM_SHAPE,
    icon: "flaticon-taxes",
    href: "/services",
    title: "Global Tax Structure",
    subtitle: "Mitigate Taxes Through Relocation And Entities.",
  },
];

export const SERVICES_THREE_LIST = [
  {
    id: 1,
    src: H4_SERVICES_IMG01,
    icon: "flaticon-healthcare",
    href: "/services-details",
    title: "Digital Nomad Visas",
    subtitle: "Specialized visa service for remote workers & nomads.",
  },
  {
    id: 2,
    src: H4_SERVICES_IMG02,
    icon: "flaticon-ship",
    href: "/services-details",
    title: "Employee Relocation",
    subtitle: "Let us help your employees relocate across the globe.",
  },
  {
    id: 3,
    src: H4_SERVICES_IMG03,
    icon: "flaticon-ship",
    href: "/services-details",
    title: "Overseas Workforce",
    subtitle: "Support with hiring global talent for your business.",
  },
  {
    id: 4,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Market Expansion",
    subtitle: "On-the-ground support to expand into new markets.",
  },
  {
    id: 5,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Realestate Discovery",
    subtitle: "Understand and experience global property markets.",
  },
  {
    id: 6,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Global Investments",
    subtitle: "Discover unique and higher yield investment options.",
  },
  {
    id: 7,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-protection",
    title: "International Assets",
    subtitle: "Banking and asset storage outisde of your country.",
  },
  {
    id: 8,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Descent Citizenship",
    subtitle: "Help to obtain citizenship from your heritgate country.",
  },
  {
    id: 9,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-healthcare",
    href: "/services-details",
    title: "Medical Tourism",
    subtitle: "Arranging higher quality and cheaper medical treatment.",
  },
  {
    id: 10,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Immigration/Residence",
    subtitle: "Obtain residence and work permits in other countries.",
  },
  {
    id: 11,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-protection",
    title: "Global Tax Structure",
    subtitle: "Mitigate taxes through relocation and entities.",
  },
  {
    id: 12,
    src: H4_SERVICES_IMG04,
    icon: "flaticon-house",
    href: "/services-details",
    title: "Inner-City Lounges",
    subtitle: "Access lounges & showers downtown. Coming Soon...",
  },
  
];

export const SERVICES_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-business-presentation",
    href: "/services-details",
    title: "Marketing Strategy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-rocket",
    href: "/services-details",
    title: "SEO Consultancy",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-mission",
    href: "/services-details",
    title: "Target Audience",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];

export const SERVICES_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-briefcase",
    href: "/services-details",
    src: SERVICES_IMG01,
    title: "Freelance Workers",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 2,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Business Owners",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 3,
    icon: "flaticon-money",
    href: "/services-details",
    src: SERVICES_IMG03,
    title: "Content Creators",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Ecommerce Stores",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
  {
    id: 4,
    icon: "flaticon-taxes",
    href: "/services-details",
    src: SERVICES_IMG02,
    title: "Entreprenuers",
    subtitle:
      "When An Unknown Printer Took Gerty Galley Types Awecr Erambled Type Specimen Book.",
  },
];
