import { RIGHT_ARROW } from "../lib/assets";

export const FEATURE_TWO_LIST = [
  {
    id: 1,
    icon: "flaticon-profit",
    title: "Growing Business",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 2,
    icon: "flaticon-investment",
    title: "Finance Investment",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Advisory",
    subtitle: "Finance helps you to convert into a strategic asset get.",
  },
];

export const FEATURE_THREE_LIST = [
  {
    id: 1,
    title: "Freelancer Plan",
    subtitle:
      "Our most affordable option designed for freelancers getting paid directly from third-party services like Amazon, YouTube and Twitch or don't need a white-label client interface. Restricted to incomes of $150K.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 2,
    title: "Entrepreneur Plan",
    subtitle:
      "Maintain control of your structure and brand whilst reducing your tax liability to as low as 10%. Ideal for investor-backed startups, those with an existing legal structure or businesses trading internationally.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 3,
    title: "Startup Plan",
    subtitle:
      "Let us act as your payment collections agent and enjoy a higher income threshold with fewer restrictions or usage limitations. Designed for larger businesses with non-client facing payment processing.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
  {
    id: 4,
    title: "Business Plan",
    subtitle:
      "Enjoy a greater level of structural control, a fully white-labeled experience and the higher-financial thresholds. Designed for businesses who need control over the brand with the maximum tax savings.",
    href: "/services-details",
    src: RIGHT_ARROW,
  },
];

export const FEATURE_FOUR_LIST = [
  {
    id: 1,
    icon: "flaticon-save-money",
    title: "Payment Handling",
    subtitle: "Automatic collecton of client payments in any currency.",
  },
  {
    id: 2,
    icon: "flaticon-handshake",
    title: "Legally Compliant",
    subtitle: "Ensures all filings are made with the relevant authorities.",
  },
  {
    id: 3,
    icon: "flaticon-taxes",
    title: "Tax Optimization",
    subtitle: "Strategic payout methods, help reduce or elimate liability.",
  },
];

export const FEATURE_ONE_LIST = [
  {
    id: 1,
    icon: "flaticon-puzzle-piece",
    title: "Quality Services",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
  {
    id: 2,
    icon: "flaticon-inspiration",
    title: "Innovation Ideas",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
  {
    id: 3,
    icon: "flaticon-profit",
    title: "Business Growth",
    subtitle: "Eiusmod Temporincididunt Ut Labore Magna Aliqua Quisery.",
  },
];
