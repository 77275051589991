import { AboutFive } from "../../components/About/AboutFive";
import { AboutThree } from "../../components/About/AboutThree";
import { BannerFour } from "../../components/Banners/BannerFour";
import { BlogAreaFour } from "../../components/BlogAreas/BlogAreaFour";
import { BrandFour } from "../../components/Brand/BrandFour";
import { ChooseAreaTwo } from "../../components/ChooseAreas/ChooseAreaTwo";
import { CtaAreaThree } from "../../components/CtaAreas/CtaAreaThree";
import { EstimateAreaOne } from "../../components/EstimateAreas/EstimateAreaOne";
import { FeatureFour } from "../../components/Features/FeatureFour";
import { PricingAreaThree } from "../../components/PricingAreas/PricingAreaThree";
import { ServicesThree } from "../../components/Services/ServicesThree";
import { ServiceOne } from "../../components/Services/ServiceOne";
import { TeamAreaFour } from "../../components/TeamAreas/TeamAreaFour";
import { TeamAreaTwo } from "../../components/TeamAreas/TeamAreaTwo";
import { ContactAreaOne } from "../../components/ContactAreas/ContactAreaOne";
import { ChooseAreaThree } from "../../components/ChooseAreas/ChooseAreaThree";
import { CounterAreaOne } from "../../components/CounterAreas/CounterAreaOne";
import { FeatureThree } from "../../components/Features/FeatureThree";
import { FaqAreaOne } from "../../components/FaqAreas/FaqAreaOne";
import { ServicesTwo } from "../../components/Services/ServicesTwo";
import { CtaAreaTwo } from "../../components/CtaAreas/CtaAreaTwo";
import { TestimonialAreaFour } from "../../components/TestimonialAreas/TestimonialAreaFour";
import { RequestAreaTwo } from "../../components/RequestAreas/RequestAreaTwo";
import { Layout } from "../../layouts/Layout";

const HomeThree = () => {
  return (
    <Layout header={4} footer={1}>
      {/* banner-area */}
      <BannerFour />

      <FeatureFour />
      <AboutThree />
      {/* <AboutFive /> */}

      {/* services-area */}
      {/* <ServiceOne /> */}
      
      {/* brand-area */}
      {/* <BrandFour /> */}

      {/* choose-area */}
      <ChooseAreaTwo />
      
      {/* cta-area */}
      <CtaAreaThree />
        
      {/* team-area */}
      <TeamAreaTwo />
      
      {/* request-area  */}
      <RequestAreaTwo />
      
      {/* cta-area */}
      {/* <CtaAreaThree /> */}
      
      {/* estimate-area */}
      <EstimateAreaOne />

      {/* testimonial-area */}
      <TestimonialAreaFour />
      
      {/* features-area */}
      <FeatureThree />

      {/* pricing-area */}
      <PricingAreaThree />
      
      
      {/* services-area */}
      <ChooseAreaThree />
      
      {/* faq-area */}
      {/* <FaqAreaOne /> */}
      
      {/* <ServicesTwo /> */}
      
      {/* contact-area */}
      <ContactAreaOne />
      
    </Layout>
  );
};

export default HomeThree;
