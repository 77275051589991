import React, { useEffect } from "react";
import {
  ABOUT_AUTHOR,
  H2_ABOUT_IMG01,
  H2_ABOUT_IMG02,
  H2_ABOUT_SHAPE01,
  H2_ABOUT_SHAPE02,
  H2_ABOUT_SHAPE03,
  SIGNATURE,
} from "../../lib/assets";

export const AboutThree = () => {
  const list = [
    "Nomadic acts as your internal collections agent",
    "Receivables are processed on your behalf",
    "Access multi-currency banking for expenses",
    "Optimized payouts are made to your account",
    "Handling of Necessary Tax Compliance Filings",
  ];

  return (
    <section className="about-area-three">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="about-img-wrap-three">
              <img
                src={H2_ABOUT_IMG01}
                alt=""
                data-aos="fade-down-right"
                data-aos-delay="0"
              />
              <img
                src={H2_ABOUT_IMG02}
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
              <div
                className="experience-wrap"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <h2 className="title">
                  40 <span>Years</span>
                </h2>
                <p>Doing Tax Effective International Trade.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-content-three">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style3">
                <span className="sub-title">How It Works</span>
                <h2 className="title tg-element-title">
                  Tax Optimized Receivables For U.S. Independent Earners
                </h2>
              </div>
              <p className="info-one">
                By leveraging a combination of global structures, our unique methodology, and automation technology, we help freelancers, small businesses, and more to legally mitigate tax liability on their profits. By optimizing how, when, and where they receive payouts, independent earners with profits under $1M can reduce their tax liability by 90% or more.
              </p>

              <div className="about-list-two">
                <ul className="list-wrap">
                  {list.map((item, idx) => (
                    <li key={idx}>
                      <i>{idx+1}</i> {item}
                    </li>
                  ))}
                </ul>
              </div>
              <p>
              The receivables and payout process is dependent on individual circumstances, but we always ensure everything is compliant.
              </p>
              <div className="about-author-info mb-30">
                <div className="thumb">
                  <img src={ABOUT_AUTHOR} alt="" />
                </div>
                <div className="content">
                  <h2 className="title">Alex Saidani</h2>
                  <span>Founder, Nomadic</span>
                </div>

              </div>
              <p>
                "Think of Nomadic as your personal collections team: we'll handle client invoices and service payments on your behalf, process them efficiently, and then pay you using the most tax-effective methods."
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-shape-wrap-two">
        <img src={H2_ABOUT_SHAPE01} alt="" />
        <img src={H2_ABOUT_SHAPE02} alt="" />
        <img
          src={H2_ABOUT_SHAPE03}
          alt=""
          data-aos="fade-left"
          data-aos-delay="500"
        />
      </div>
    </section>
  );
};
